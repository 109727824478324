import { CSSProperties, FC, MouseEvent, useEffect, useRef } from 'react';
import { tw } from '@utils/tailwind-utils';
import clsx from 'clsx';

import { Item } from './Autocomplete';

interface AutocompleteBodyProps {
  show: boolean;
  items: Item[];
  onItemSelect: (e: MouseEvent<HTMLLIElement>) => void;
  activeItem: number;
  className?: string;
  styles?: CSSProperties;
}

const AutocompleteBody: FC<AutocompleteBodyProps> = ({ show, items, onItemSelect, activeItem, className, styles }) => {
  const selectedRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const activeNode = selectedRef.current?.children[activeItem];
    if (activeNode) {
      if ('scrollIntoViewIfNeeded' in activeNode && typeof activeNode.scrollIntoViewIfNeeded === 'function') {
        activeNode.scrollIntoViewIfNeeded();
      }
    }
  }, [activeItem]);

  if (!show) {
    return null;
  }
  if (items.length) {
    return (
      <div
        style={styles}
        className={tw(
          'z-20 mt-1 rounded-[20px] border-rentennials-inputBg bg-white py-3.5 shadow-xl',
          'absolute border-[1px]',
          className
        )}
      >
        <ul
          ref={selectedRef}
          className="
        scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-200, max-h-[200px] overflow-auto font-bree-serif
        text-[15px] font-normal
        text-rentennials-primary scrollbar-thin
        [&>li:nth-child(n)]:mb-1
        "
        >
          {items.map(({ label, value, leftItemIcon }, index) => {
            return (
              <li
                className={clsx(
                  'mx-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-md py-1 hover:bg-rentennials-inputBg',
                  {
                    'rounded-md bg-rentennials-inputBg': index === activeItem
                  }
                )}
                key={`${value}+${index}`}
                onClick={onItemSelect}
              >
                <div className="flex">
                  {leftItemIcon && <div className="pl-3">{leftItemIcon}</div>}
                  <span className="autocompleteText px-3">{label}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  return (
    <div
      style={styles}
      className={tw(
        'absolute z-20 mt-1 rounded-[20px] border-[1px] border-rentennials-inputBg bg-white px-4  py-2 shadow-xl',
        className
      )}
    >
      <span className="font-bree-serif font-normal text-rentennials-primary">No hay Resultados</span>
    </div>
  );
};

export default AutocompleteBody;
