import { FC, useEffect, useState } from 'react';
import {
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery
} from '@chakra-ui/react';
import { Size } from '@components/common/size';
import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  convertToUTC,
  convertVehicleDateToBrowserDateStartOfDay,
  getDifferenceBetweenDates,
  getMinDate,
  isRangeDateValid,
  setNewTZ
} from '@utils/dates';
import { rentennialsTimeUtils } from '@utils/dates/rentennialDateTZ';
import { DateTime } from 'luxon';
import { useTranslation } from 'next-i18next';

import { RangeCalendarPanel } from './aeht-chakra-dayzed-datepicker/src';
import { defaultCalendarConfig } from './calendarConfig';

interface DatePickRangeModalProps {
  isDisponibilityPicker?: boolean;
  timezone: string;
  size?: Size;
  dateFrom: Date | string;
  dateTo: Date | string;
  disableDates?: Set<number>;
  specialDates?: Set<number>;
  specialDateText?: string;
  specialBtnProps?: ButtonProps;
  isInRangeAndSepcialBtnProps?: ButtonProps;
  minDateDifference?: number;
  minDate?: Date;
  onClose: () => void;
  onDateChange: (dateFrom: string, dateTo: string) => void;
  noDates?: boolean;
  onExtraValidation?: (date1: string, date2: string) => string | undefined;
}

const DatePickRangeModal: FC<DatePickRangeModalProps> = ({
  timezone,
  onClose,
  onDateChange,
  size,
  dateFrom,
  dateTo,
  disableDates,
  specialDates,
  minDateDifference = 2,
  minDate,
  noDates = false,
  specialBtnProps,
  isInRangeAndSepcialBtnProps,
  specialDateText,
  onExtraValidation,
  isDisponibilityPicker
}) => {
  const { t } = useTranslation(['search', 'result']);

  const [selectedDates, setSelectedDates] = useState<DateTime[]>(
    isDisponibilityPicker
      ? [convertVehicleDateToBrowserDateStartOfDay(dateFrom), convertVehicleDateToBrowserDateStartOfDay(dateTo)]
      : [convertToUTC(dateFrom).startOf('day'), convertToUTC(dateTo).startOf('day')]
  );
  const [twoPerSide] = useMediaQuery(['(min-width: 768px)']);
  const [initialDate, setInitialDate] = useState<Date>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const deviceTimezone = rentennialsTimeUtils.offset.getCurrentTZ();

  useEffect(() => {
    setNewTZ(timezone);
    return () => {
      setNewTZ(deviceTimezone);
    };
  }, [timezone, deviceTimezone]);

  const handleDateChange = (date1: DateTime, date2: DateTime) => {
    onDateChange(date1.toISO()!, date2.toISO()!);
  };

  const handleOnDateSelected = ({ selectable, date }: { selectable: boolean; date: Date }) => {
    if (!selectable) {
      return;
    }
    const currentSelectedDateTime = rentennialsTimeUtils.transform.convertBrowserDateToVehicleDateStartOfDay(date);

    let newDates = [...selectedDates];
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        let firstTime = selectedDates[0];
        if (firstTime < currentSelectedDateTime) {
          newDates.push(currentSelectedDateTime);
        } else {
          newDates.unshift(currentSelectedDateTime);
        }
        if (disableDates && !isRangeDateValid(newDates, disableDates)) {
          setSelectedDates(selectedDates);
          setErrorMessage('range-pick.errors.blocked');
          return;
        }
        if (getDifferenceBetweenDates(newDates[1], newDates[0]) < minDateDifference) {
          setSelectedDates(selectedDates);
          setErrorMessage('range-pick.errors.not-in-range');
          return;
        }
        const extraValidationMessage = onExtraValidation?.(newDates[1].toISO()!, newDates[0].toISO()!);
        if (extraValidationMessage) {
          setSelectedDates(selectedDates);
          setErrorMessage(extraValidationMessage);
          return;
        }
        handleDateChange(newDates[0], newDates[1]);
        onClose();
      }

      if (newDates.length === 2) {
        setSelectedDates([currentSelectedDateTime]);
        setInitialDate(date);
        return;
      }
    } else {
      newDates.push(currentSelectedDateTime);
      setSelectedDates(newDates);
    }
  };

  const calConfig = {
    ...defaultCalendarConfig,
    dayNames: defaultCalendarConfig.dayNames.map((val) => t(val)),
    monthNames: defaultCalendarConfig.monthNames.map((val) => t(val))
  };

  return (
    <Modal isOpen onClose={onClose} isCentered size={twoPerSide ? '2xl' : ['xs', 'sm']} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        sx={{
          borderRadius: '20px'
        }}
      >
        <ModalHeader>
          <h2 className="pl-4 font-bree-serif font-normal">{t('range-pick.title')}</h2>
          <div className="pt-2">
            <hr />
          </div>
        </ModalHeader>
        <ModalCloseButton>
          <XMarkIcon className="inline h-6 w-6 stroke-2  text-[#B7B7B7]" />
        </ModalCloseButton>
        <ModalBody
          sx={{ pb: 4, pt: 2, display: 'flex', columnGap: '4px', flexDir: 'column', justifyContent: 'center' }}
          className="rentennials-picker"
        >
          <RangeCalendarPanel
            dayzedHookProps={{
              onDateSelected: handleOnDateSelected,
              monthsToDisplay: 2,
              minDate: minDate || getMinDate().date.toJSDate(),
              selected: noDates
                ? []
                : selectedDates.map((sd) => convertVehicleDateToBrowserDateStartOfDay(sd).toJSDate()),
              date: new Date()
            }}
            selected={
              noDates
                ? !initialDate
                  ? undefined
                  : [initialDate]
                : selectedDates.map((sd) => convertVehicleDateToBrowserDateStartOfDay(sd).toJSDate())
            }
            disabledDates={disableDates}
            specialDates={specialDates}
            configs={calConfig}
            propsConfigs={{
              dateNavBtnProps: {
                colorScheme: 'white',
                variant: 'outline'
              },
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  borderColor: 'red.300',
                  _hover: {
                    background: 'rentennials.primary'
                  }
                },
                especialBtnProps: {
                  color: 'white !important',
                  ...specialBtnProps
                },
                isInRangeBtnProps: {
                  color: 'white'
                },
                isInRangeAndSepcialBtnProps: {
                  ...isInRangeAndSepcialBtnProps
                },
                selectedBtnProps: {
                  background: 'rentennials.primary',
                  color: 'white'
                },
                todayBtnProps: {
                  background: 'blackAlpha.200'
                }
              },
              inputProps: {
                size: size === 'sm' ? 'xs' : 'sm',
                w: '100%',
                px: 4,
                fontFamily: 'Roboto',
                fontSize: size === 'sm' ? '12px' : '14px',
                fontWeight: 400,
                lineHeight: '18px',
                color: 'rentennials.input'
              },
              popoverCompProps: {
                popoverContentProps: {
                  color: 'blackAlpha.800',
                  borderRadius: 20,
                  boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);'
                }
              }
            }}
          />
          <div className="h-5 px-4 font-bree-serif text-xs font-bold leading-5 tracking-[0.12px] text-rentennials-inputError">
            {!errorMessage && specialDates && (
              <>
                <hr />
                <div className="justify-left flex items-center gap-x-4 pt-1">
                  <div
                    className="h-5 w-5 rounded-md"
                    style={{
                      backgroundColor: specialBtnProps?.backgroundColor?.toString() || '#D13359'
                    }}
                  />
                  {specialDateText}
                </div>
              </>
            )}
            {errorMessage && (
              <>
                <hr />
                <span className="pt-1">{t(errorMessage)}</span>
              </>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DatePickRangeModal;
