import { FocusEvent, KeyboardEvent, MouseEvent, RefObject, useEffect, useState } from 'react';
import { useOutsideClick } from '@chakra-ui/react';
import { checkIfParent } from '@utils/index';

import { Item } from './Autocomplete';

export const useAutocompleteActions = (
  data: Item[],
  autocompleteRef: RefObject<HTMLElement>,
  autocompleteId: string,
  setCurrentSelectedValue: (isOutside: boolean, value?: Item) => void,
  onResetComplete: () => void,
  currentSelectedValue?: Item,
  isEditable?: boolean,
  shouldReset?: boolean
) => {
  const [active, setActive] = useState<number | null>(null);
  const [filteredData, setFilteredData] = useState<Item[]>(data);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (active === null && currentSelectedValue) {
      setActive(data.findIndex(({ value }) => currentSelectedValue.value === value));
    }
  }, [active, currentSelectedValue, data]);

  useEffect(() => {
    if (shouldReset) {
      setFilteredData(data);
      onResetComplete();
    }
  }, [data, onResetComplete, shouldReset]);

  const handleCloseOnInteraction = (isOutside = false) => {
    if (isVisible) {
      setCurrentSelectedValue(
        isOutside,
        (filteredData.length ? filteredData : data)[active || 0]?.value === currentSelectedValue?.value
          ? currentSelectedValue
          : undefined
      );
      setIsVisible(false);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (
      e.relatedTarget &&
      !checkIfParent(e.relatedTarget, autocompleteId) &&
      !checkIfParent(e.currentTarget, autocompleteId)
    ) {
      handleCloseOnInteraction(true);
    }
  };

  useOutsideClick({
    ref: autocompleteRef,
    handler: handleCloseOnInteraction.bind(null, true)
  });

  const handleShowResults = (inputValue?: string) => {
    setIsVisible(true);
    if (typeof inputValue !== 'undefined') {
      const newFilteredDataSuggestions = inputValue
        ? data.filter((data) => data.label.toLowerCase().includes(inputValue.toLowerCase()))
        : data;

      setFilteredData(newFilteredDataSuggestions);

      if (currentSelectedValue) {
        setActive(
          (newFilteredDataSuggestions.length ? newFilteredDataSuggestions : data).findIndex(
            ({ value }) => currentSelectedValue.value === value
          )
        );
      }
      setCurrentSelectedValue(
        false,
        data.find(({ label }) => label === inputValue)
      );
    }
  };

  const handleChange = (value: string) => {
    handleShowResults(value);
  };

  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    setActive(
      (filteredData.length ? filteredData : data).findIndex(
        ({ label }) =>
          label === (e.currentTarget.getElementsByClassName('autocompleteText')[0] as HTMLElement).innerText
      )
    );
    setFilteredData([]);
    setIsVisible(false);
    setCurrentSelectedValue(
      false,
      data.find(
        ({ label }) =>
          label === (e.currentTarget.getElementsByClassName('autocompleteText')[0] as HTMLElement).innerText
      )
    );
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setIsVisible(false);
      setCurrentSelectedValue(false, (filteredData.length ? filteredData : data)[active || 0]);
    }
    if (e.key === 'Escape') {
      // enter key
      setActive(0);
      setIsVisible(false);
      setCurrentSelectedValue(false);
    } else if (e.key === 'ArrowUp') {
      // up arrow
      return !active ? null : setActive(active - 1);
    } else if (e.key === 'ArrowDown') {
      // down arrow
      if (!isEditable) {
        return (active || 0) + 1 === data.length ? data.length : setActive((active || 0) + 1);
      }
      return (active || 0) + 1 === filteredData.length ? filteredData.length : setActive((active || 0) + 1);
    }
  };

  return {
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onClick: handleClick,
    onBlur: handleBlur,
    onShowResults: handleShowResults,
    activeItem: active || 0,
    isVisible,
    filteredData
  };
};
