import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DateType } from '@api/types/dateSchema';
import { SystemStyleObject } from '@chakra-ui/react';
import Autocomplete, { Item } from '@components/autocomplete/Autocomplete';
import { buildItem } from '@components/autocomplete/utils';
import { Size } from '@components/common/size';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { halfHours } from '@utils/dates';

interface TimePickProps {
  size?: Size;
  controlName: 'fromDateTime' | 'toDateTime';
  className?: string;
  inputSx?: SystemStyleObject;
  noDates?: boolean;
  hours?: string[];
}

const TimePick: FC<TimePickProps> = ({ controlName, size, className, inputSx, noDates = false, hours = halfHours }) => {
  const buildHourItem = (hour: string): Item => ({ value: hour, label: hour });

  const items = buildItem(buildHourItem, hours);

  const { control } = useFormContext<Pick<DateType, 'fromDateTime' | 'toDateTime'>>();

  return (
    <Controller
      control={control}
      name={controlName}
      render={({ field: { value, onChange, ref } }) => (
        <Autocomplete
          setCurrentValue={(hour?: Item) => {
            onChange(hour?.value || hours[0]);
          }}
          currentValue={
            noDates
              ? undefined
              : items.find(({ value: itemValue }) => {
                  return itemValue === value;
                })
          }
          ref={ref}
          items={items}
          placeholder="-"
          isEditable={false}
          preventFilter
          size={size}
          className={className}
          autocompleteBodyClassName="text-center"
          rightIcon={<ChevronDownIcon className="h-4 w-4 stroke-2 text-rentennials-input" />}
          sx={{
            textAlign: 'center',
            minW: '98px',
            '&:hover': {
              opacity: 0.7,
              bg: 'rentennials.inputBg'
            },
            ...(inputSx && { ...inputSx }),
            borderTopStartRadius: 0,
            borderBottomStartRadius: 0
          }}
        />
      )}
    />
  );
};

export default TimePick;
